import React from 'react'
import './Not-found.css'
import img from '../../assets/Image.png'
import { ArrowLeft, LogoutCurve } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
const Notfound = (props) => {
    const nav =useNavigate()
    const url = window.location.href;
    const urlObject = new URL(url);
    const pathArray = urlObject.pathname.split('/');
    const desiredWord = pathArray[1];
const distroy=()=>{
  localStorage.removeItem("persist:mySecureKey"); 
   nav("/home");nav(0)
}
const LoginServiceData = useSelector((state) => state.loginservice);


  return (
    <div className='Notfound'>
<div className='col0-Notfound'>
<div className='col-Notfound '>
<div><p className='txt-Notfound'>Erreur 404</p></div>
<div><p className='txt2-Notfound'>Page non trouvée</p></div>
<div className='txfound'><p className='txt3-Notfound'>Désolé, la page que vous recherchez n'existe pas. Voici quelques liens utiles :</p></div>
<div className='row-Notfound'>

<button className='bnt-Notfound'onClick={()=>{nav("/"+desiredWord);nav(0)}} >
<ArrowLeft
 size="22"
/>
<div><p>Retourner</p></div>
</button>
<button className='bnt2-Notfound' onClick={()=>{nav("/")}}>Page d'accueil</button>

{
  LoginServiceData.isLogin?
<div className='bnt2-Notfound' onClick={()=>{distroy()}}>    
<div className="row1-profile">
                      <LogoutCurve size="20" />
                      <div className="txt-icon">Déconnection</div>
                    </div></div>
:
null
}


</div>

      </div>
</div>
      <div>
        <img src={img} className='img-notfond'/>
      </div>
    </div>
  )
}

export default Notfound
